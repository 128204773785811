
import React from "react";
import { graphql } from "gatsby";
import Footer from "../components/Footer";
import SEO from "../components/seo";
import Header from "../components/Header";
import PageWrapper from "../PageWrapper";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon
} from 'react-share'

//                        { data, pageContext, location }
const BlogPostTemplate = ( { data } ) => {

  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  // const { previous, next } = pageContext;

  return (
    <PageWrapper>
      <Header title={ siteTitle } />
      <SEO
        title={ post.frontmatter.title }
        description={ post.excerpt }
      />
      {/* <!--====== BLOG DETAILS PART START ======--> */ }

      <section className="blog-details-area pt-70 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="blog-details-content mt-50">
                <h1 className="details-title">{ post.frontmatter.title }</h1>
                <p className="text-muted">{ post.frontmatter.date }</p>
                <hr />

                <section className="blog-main-content" dangerouslySetInnerHTML={ { __html: post.html } }></section>

                {/*
                          <blockquote className="blockquote">
                              <img className="quote" src="assets/images/quote.png" alt="quote" />
                              <p className="text"> <i className="fa fa-quote-left"></i> There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words. which don't look. <i className="fa fa-quote-right"></i></p>
                          </blockquote>
                          */}

                <div className="blog-share d-flex">
                  <span>Share:</span>
                  <ul className="social">
                    <li>
                      <FacebookShareButton url={ data.site.siteMetadata.domain + post.frontmatter.permalink }>
                        <FacebookIcon size={48} />
                      </FacebookShareButton>
                    </li>
                    <li>
                      <TwitterShareButton url={ data.site.siteMetadata.domain + post.frontmatter.permalink } title={ post.frontmatter.title } via={ data.site.siteMetadata.social.twitter }>
                        <TwitterIcon size={48} />
                      </TwitterShareButton>
                    </li>
                    <li>
                      <LinkedinShareButton url={ data.site.siteMetadata.domain + post.frontmatter.permalink } title={ post.frontmatter.title }>
                        <LinkedinIcon size={48} />
                      </LinkedinShareButton>
                    </li>
                    {/*
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="color-1"
                        href={ `https://www.facebook.com/sharer/sharer.php?u=${ data.site.siteMetadata.domain + post.frontmatter.permalink }&t=${post.frontmatter.title}` }
                      >
                        <i className="lni-facebook-filled"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="color-2"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={ `https://twitter.com/intent/tweet/?text=${post.frontmatter.title}&url=${ data.site.siteMetadata.domain + post.frontmatter.permalink }&via=${ data.site.siteMetadata.social.twitter }` }>
                        <i className="lni-twitter-original"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="color-4"
                        href={ `https://www.linkedin.com/shareArticle?mini=true&url=${ data.site.siteMetadata.domain + post.frontmatter.permalink }&title=${post.frontmatter.title}&source=${post.frontmatter.title}` }
                      >
                        <i className="lni-linkedin-original"></i>
                      </a>
                    </li>
                    */}
                  </ul>
                </div>

              </div>

            </div>
          </div>
        </div>
      </section>

      {/* <!--====== BLOG DETAILS PART ENDS ======--> */ }


      {/*
      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
      */}

      <Footer />

    </PageWrapper>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        domain: siteUrl
        social {
          twitter
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        permalink
      }
    }
  }
`
